
.event-card {
    transition: transform 0.2s, box-shadow 0.2s;
  }
  

  .event-card:hover {
    transform: scale(1.05); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  