::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgb(125, 122, 122);
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgb(31, 30, 30); 
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(55, 54, 54); 
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;